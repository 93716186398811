import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DecimalPipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";

import { SwiperConfigInterface } from "ngx-swiper-wrapper";

import { Settings, AppSettings } from "src/app/app.settings";

import { Product } from "../../app.models";
import { Data, AppService } from "../../app.service";

import { ProductDialogComponent } from "./product-dialog/product-dialog.component";

@Component({
  selector: "app-products-carousel",
  templateUrl: "./products-carousel.component.html",
  styleUrls: ["./products-carousel.component.scss"]
})
export class ProductsCarouselComponent implements OnInit {
  @Input("products") products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;

  private codTabelaPreco: string = '';

  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    const codTabelaPreco = JSON.parse(localStorage.information);
    this.codTabelaPreco = codTabelaPreco.codTabelaPreco;
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: true,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 3
        },
        1500: {
          slidesPerView: 4
        }
      }
    };
  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
      direction: this.settings.rtl ? "rtl" : "ltr"
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(["/products", product.id, product.name]);
      }
    });
  }

  public formatterValue(valor) {
    let val = (valor / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
}
