import { Component, OnInit, Input } from '@angular/core';

import { Menu } from 'src/app/interfaces/menu.model';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/session/session.service';
import { interval } from 'rxjs';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menu: Array<any> = [];

  public codTabelaPreco: string;

  constructor(
    private homeService: HomeService,
    private sessionService: SessionService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.sessionService.information$.subscribe((data) => {
      this.codTabelaPreco = data.codTabelaPreco;
    })
    this.getMenu();
  }

  getMenu() {
    this.homeService.homeSessoes(this.codTabelaPreco).subscribe((data) => {
      data.menus.sort((a, b) => {
        if (a.ordem < b.ordem) {
          return -1;
        } else if (a.ordem > b.ordem) {
          return 1;
        } else {
          return 0;
        }
      })
      data.menus.map((item) => {
        if (item.subMenu.length <= 1) {
          item.subMenu = [];
        }
        item.subMenu.unshift({ codigo: item.codigo, descricao: 'Todos', breadcrumb: 'Todos Produtos', exibeAgrupamentoCores: item.exibeAgrupamentoCores, ordem: 0 });
        // item.subMenu.unshift({ codigo: item.codigo, descricao: 'Todos', breadcrumb: 'Todos Produtos' });
        item.subMenu.sort((a, b) => {
          if (a.ordem < b.ordem) {
            return -1;
          } else if (a.ordem > b.ordem) {
            return 1;
          } else {
            return 0;
          }
        })
        item.subMenu.map((sub, i) => {
          sub.breadcrumb = `${item.descricaoMenu} - ${sub.descricao}`;
          sub.descricao = this.utilService.convertFirstUpperCase(sub.descricao);
        })
        this.menu.push({ label: item.descricaoMenu, route: item.codigo, subTitulo: item.subTitulo, ordem: item.ordem, subMenu: item.subMenu, exibeAgrupamentoCores: item.exibeAgrupamentoCores })
        // this.menu.push({ label: item.descricaoMenu, route: item.codigo, subTitulo: item.subTitulo, ordem: item.ordem, subMenu: item.subMenu });
      })
    })
    this.ordenarMenu();
  }

  ordenarMenu() {
    this.menu = this.menu.sort((a, b) => {
      if (a.ordem < b.ordem) {
        return -1;
      } else if (a.ordem > b.ordem) {
        return 1;
      } else {
        return 0;
      }
    })
  }

}
