import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { emailValidator } from 'src/app/theme/utils/app-validators';
import { ObterIdentificador, ObterIdentificadorService } from 'src/app/services/sign-in/obter-identificador.service';
import { NewsletterService } from 'src/app/services/newsletter/newsletter.service';
import { SessionService, SignIn } from 'src/app/services/session/session.service';
import { RedesSociaisFooter } from 'src/app/interfaces/redes-sociais-footer.model';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12;
  public disabled: boolean = true

  newsForm: FormGroup;

  dadosEmpresa: ObterIdentificador

  codCliente: string;

  showNews: boolean = false;

  public provedor: string;
  public sac: string;
  public site: string;
  public marca: string;
  private redesMaiori: RedesSociaisFooter = {
    youtube: 'https://www.youtube.com/channel/UC_XCs9L_jTHlQou4VIyWyew/videos',
    facebook: 'https://www.facebook.com/maioricasa',
    instagram: 'https://www.instagram.com/maioricasa/'
  }
  private redesAsia: RedesSociaisFooter = {
    youtube: 'https://www.youtube.com/channel/UCWHn0Dof-NAJEWtpXrJbhTQ/videos',
    facebook: 'https://www.facebook.com/maioricasa',
    instagram: 'https://www.instagram.com/asiatexbr/'
  }
  public redesBase: RedesSociaisFooter = {
    youtube: '',
    facebook: '',
    instagram: ''
  }
  public whatsapp: string;
  public email: string;
  public identificador: number;
  public sobreNos: string;
  public faleConosco: string;

  constructor(
    private fb: FormBuilder,
    private obterIdentificadorService: ObterIdentificadorService,
    private newsletterService: NewsletterService,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    public router: Router,
    private location: Location,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.sessionService.information$
      .subscribe((data: SignIn) => {
        if (data.codCliente == null || data.codCliente == '') {
          this.codCliente = ''
        } else {
          this.codCliente = data.codCliente
        }
      })

    this.newsForm = this.fb.group({
      nome: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      telefone: ['', Validators.compose([Validators.required, Validators.maxLength(11), Validators.minLength(11)])]
    })

    this.newsForm.valueChanges
      .subscribe((data) => {
        this.disabled = this.newsForm.invalid;
      });

    this.obterIdentificadorService
      .obterIdentificador()
      .subscribe((data: ObterIdentificador) => {
        this.identificador = data.identificador;
        this.marca = data.identificador == 67365910546 ? 'Maiori Casa'.toUpperCase() : 'A Asiatex'.toUpperCase();
        this.redesBase = data.identificador == 67365910546 ? this.redesMaiori : this.redesAsia;
        this.provedor = data.site.split('www.')[1];
        this.sac = `mailto:sac@${this.provedor}`;
        this.site = `http://institucional.${this.provedor}`;
        this.whatsapp = this.utilService.mascaraDeTelefone(data.whatsapp.substr(2));
        this.email = `${data.identificador == 67365910546 ? 'contato' : 'vendas'}@${this.provedor}`;
        this.sobreNos = `${this.site}/${data.identificador == 67365910546 ? 'sobre.php' : 'sobre'}`;
        this.faleConosco = `${this.site}/${data.identificador == 67365910546 ? 'contact.php' : '#divConosco'}`;

        this.dadosEmpresa = data;
        const valorAtual = data.telefone;
        const isCelular = valorAtual.length === 9
        let telMask;
        if (isCelular) {
          const x = valorAtual.slice(0, 5);
          const y = valorAtual.slice(5, 9);
          telMask = `${x}-${y}`
        } else {
          const x = valorAtual.slice(0, 4);
          const y = valorAtual.slice(4, 8);
          telMask = `${x}-${y}`
        }
        data.telefone = telMask;
      })

    this.router.events.subscribe(event => {
      let urlLocation = this.location.path();
      urlLocation === '/cart' || urlLocation === '/checkout' ? this.showNews = false : this.showNews = true
    })

  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let urlLocation = this.location.path();
        urlLocation === '/cart' || urlLocation === '/checkout' ? this.showNews = false : this.showNews = true
      }
    })
  }

  get controls() {
    return this.newsForm.controls;
  }
  cadastrarUser() {
    const ddd = this.controls['telefone'].value.slice(0, 2);
    const tel = this.controls['telefone'].value.slice(2, 11);
    let obj = {
      nome: this.controls['nome'].value,
      email: this.controls['email'].value,
      ddd: ddd,
      telefone: tel,
      codCliente: this.codCliente
    }
    console.log(obj);
    this.newsletterService.receberNews(obj)
      .subscribe((data) => {
        this.snackBar.open('Cadastro Realizado com Sucesso!', '', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
        this.newsForm.reset();
        window.scrollTo(0, 0);
      },
        (error: HttpErrorResponse) => {
          this.snackBar.open('Erro ao Cadastrar!', error.error['mensagem'], { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          console.error(error.error['mensagem'])
        })

  }

  generateUrl(url, params) {
    var i = 0, key;
    for (key in params) {
      if (i === 0) {
        url += "?";
      } else {
        url += "&";
      }
      url += key;
      url += '=';
      url += params[key];
      i++;
    }
    return url;
  }

  irParaConfigurador() {

    this.sessionService.information$.subscribe((data) => {
      let codCLiente = data['codCliente'];
      let token = data['token'];
      window.location = this.generateUrl("http://configurador-b2c.mustti.com.br", {
        token: token,
        codCLiente: codCLiente
      });

    });
  }

}