import { Component, OnInit } from '@angular/core';
import { Data, AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import { SessionService, SignIn } from 'src/app/services/session/session.service';
import { ObterIdentificador, ObterIdentificadorService } from 'src/app/services/sign-in/obter-identificador.service';
import { CatalogoLink } from 'src/app/interfaces/catalogo-link.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = ['USD', 'EUR'];
  public currency: any;
  public flags = [
    { name: 'English', image: 'assets/images/flags/gb.svg' },
    { name: 'German', image: 'assets/images/flags/de.svg' },
    { name: 'French', image: 'assets/images/flags/fr.svg' },
    { name: 'Russian', image: 'assets/images/flags/ru.svg' },
    { name: 'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag: any;
  public userInformation: SignIn

  private catalogosAsia: Array<CatalogoLink> = [
    { href: 'http://institucional.asiatex.com.br/assets/pdf/catalogo_decora.pdf', name: 'Decoração', icon: 'library_books' },
    { href: 'http://institucional.asiatex.com.br/assets/pdf/catalogo_tapetex.pdf', name: 'Tapetes', icon: 'library_books' },
  ];

  private catalogosMaiori: Array<CatalogoLink> = [
    { href: 'http://institucional.maioricasa.com.br/catalogos/catalogo_de_tecido_sem_preco.pdf', name: 'Tecidos', icon: 'library_books' },
    { href: 'http://institucional.maioricasa.com.br/catalogos/feito_a_maquina_sem_preco.pdf', name: 'Tapetes Feitos à Máquina', icon: 'library_books' },
    { href: 'http://institucional.maioricasa.com.br/catalogos/tapete_feito_a_mao_em_preco_turcos.pdf', name: 'Tapetes Feitos à Mão Turca', icon: 'library_books' }
  ];

  public catalogosBase: Array<CatalogoLink>;

  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    private sessionService: SessionService,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.sessionService.information$
      .subscribe((data: SignIn) => {
        this.userInformation = data
      })

    this.currency = this.currencies[0];
    this.flag = this.flags[0];

    this.sessionService
      .identificador$
      .subscribe((data) => {
        this.catalogosBase = data.identificador == 67365910546 ? this.catalogosMaiori : this.catalogosAsia;
      },
        (error: HttpErrorResponse) => {
          console.log(error);
        })
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }

  public changeLang(flag) {
    this.flag = flag;
  }

  logout() {
    this.sessionService.logout();
  }



}
