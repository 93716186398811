import { Component, OnInit, Input } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';
import { HomeService } from 'src/app/services/home/home.service';
import { Menu } from 'src/app/interfaces/menu.model';
import { interval } from 'rxjs';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [SidenavMenuService]
})
export class SidenavMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Array<any>;
  menu: Array<Menu> = [];
  public codTabelaPreco: string;

  constructor(
    private sidenavMenuService: SidenavMenuService,
    private homeService: HomeService,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.sessionService.information$.subscribe((data) => {
      this.codTabelaPreco = data.codTabelaPreco;
    })
    this.getMenu(this.codTabelaPreco);
  }

  private getMenu(codTabelaPreco: string) {
    this.homeService.homeSessoes(codTabelaPreco).subscribe((data) => {

      data.menus.sort((a, b) => {
        if (a.ordem < b.ordem) {
          return -1;
        } else if (a.ordem > b.ordem) {
          return 1;
        } else {
          return 0;
        }
      })

      data.menus.map((item, index) => {
        item.subMenu.sort((a, b) => {
          if (a.ordem < b.ordem) {
            return -1;
          } else if (a.ordem > b.ordem) {
            return 1;
          } else {
            return 0;
          }
        })
        if (item.subMenu.length <= 1) {
          item.subMenu = [];
          item.subMenu.unshift({ codigo: item.codigo, descricao: 'Todos', breadcrumb: 'Todos Produtos', exibeAgrupamentoCores: item.exibeAgrupamentoCores, ordem: item.ordem })
        }
        this.menu.push({ label: item.descricaoMenu, route: `/products/${item.descricaoMenu}/${item.codigo}`, ordem: item.ordem, subMenu: item.subMenu, id: index })
      })
    })
  }

  onClick(menuId) {
    this.sidenavMenuService.toggleMenuItem(menuId);
    // console.log(this.menuItems)
    this.sidenavMenuService.closeOtherSubMenus(this.menu, menuId);
  }
}
